import { classList } from '@Helpers/className';
import { THSelect } from '../THSelect/THSelect';
import React, { useMemo } from 'react';
import UserAvatar from '@Atoms/UserAvatar/UserAvatar';
import { IconLabel } from '@Atoms/Info';
import { DefaultOptionType, SelectProps, SelectValue } from 'antd/lib/select';
import { UserOutput } from 'thehive-sdk';
import { Tag } from 'antd';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';

import './THUserSelector.scss';

interface THUserSelectorProps<T extends SelectValue> extends SelectProps<T> {
    users?: UserOutput[];
    borderless?: boolean;
}

const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} className="th-user-selector-tag">
            {label}
        </Tag>
    );
};

export const THUserSelector = <T extends SelectValue>({
    users = [],
    className,
    allowClear = true,
    borderless = true,
    ...props
}: THUserSelectorProps<T>) => {
    const options = useMemo<DefaultOptionType[]>(
        () =>
            users
                ?.sort(({ name: nameA }, { name: nameB }) => nameA.localeCompare(nameB))
                .map(({ name, login, avatar, _id }) => ({
                    label: <IconLabel key={_id} icon={<UserAvatar name={name} avatar={avatar} />} label={name} />,
                    value: login,
                })),
        [users],
    );

    return (
        <THSelect
            allowClear={allowClear}
            borderless={borderless}
            showSearch
            className={classList('th-user-selector', className)}
            filterOption={(inputValue, option) => {
                const label = (option?.label as any).props.label || '';
                const value = option?.value + '' || '';

                return (
                    label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
                    value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                );
            }}
            options={options}
            tagRender={tagRender}
            showArrow={false}
            {...props}
        />
    );
};
