import { NotifierOutput, SSLConfigInput, WsConfigInput } from 'thehive-sdk';

export const computeWsConfig = (wsConfig: WsConfigInput, previousWsConfig?: WsConfigInput): WsConfigInput => {
    const acceptAnyCertificate = !wsConfig.ssl.loose.checkCertificateAuthority;
    delete wsConfig.ssl.loose.checkCertificateAuthority;

    const trustManagerValue = previousWsConfig ? previousWsConfig.ssl.trustManager : wsConfig.ssl.trustManager;

    return {
        ...wsConfig,
        ssl: {
            ...wsConfig.ssl,
            loose: {
                ...wsConfig.ssl.loose,
                acceptAnyCertificate,
            },
            trustManager: acceptAnyCertificate === false ? trustManagerValue : {},
        },
    };
};

export const computeSSLConfig = (sslConfig: SSLConfigInput, previousSSLConfig?: SSLConfigInput): SSLConfigInput => {
    const acceptAnyCertificate = !sslConfig.loose.checkCertificateAuthority;
    delete sslConfig.loose.checkCertificateAuthority;

    const trustManagerValue = previousSSLConfig ? previousSSLConfig.trustManager : sslConfig.trustManager;

    return {
        ...sslConfig,
        loose: {
            ...sslConfig.loose,
            acceptAnyCertificate,
        },
        trustManager: acceptAnyCertificate === false ? trustManagerValue : {},
    };
};

export const computeNotifierWsConfig = (name: string, defaultValues?: NotifierOutput): WsConfigInput | undefined => {
    if (defaultValues?.name === 'HttpRequest' && !defaultValues.endpoint) {
        const { wsConfig } = defaultValues;

        return (
            wsConfig && {
                ...wsConfig,
                ssl: {
                    ...wsConfig.ssl,
                    loose: {
                        ...wsConfig.ssl.loose,
                        checkCertificateAuthority: !wsConfig.ssl.loose.acceptAnyCertificate,
                    },
                },
            }
        );
    }

    if (name === 'HttpRequest') {
        return {
            proxy: {
                state: 'disabled',
                protocol: 'http',
            },
            ssl: {
                loose: {
                    checkCertificateAuthority: true,
                    acceptAnyCertificate: false,
                    disableHostnameVerification: false,
                },
            },
        };
    }
};

export const computeNotifierSSLConfig = (name: string, defaultValues?: NotifierOutput): SSLConfigInput | undefined => {
    if (defaultValues?.name === 'Redis') {
        const { sslConfig } = defaultValues;

        return (
            sslConfig && {
                ...sslConfig,
                loose: {
                    ...sslConfig.loose,
                    checkCertificateAuthority: !sslConfig.loose.acceptAnyCertificate,
                },
            }
        );
    }

    if (name === 'Redis') {
        return {
            loose: {
                checkCertificateAuthority: true,
                acceptAnyCertificate: false,
                disableHostnameVerification: false,
            },
        };
    }
};
