import { ICONS } from '@Constants/iconsApp';
import { useLocalMessage } from '@Helpers/locale';
import { typeGuard } from '@Helpers/types';
import { THTabs } from '@Molecules/Inputs/THTabs/THTabs';
import PrivateRoute from '@Molecules/PrivateRoute/PrivateRoute';
import { THLayout } from '@Molecules/THLayout';
import RouteBuilder from '@Services/RouteBuilder';
import { RouteType } from '@Types';
import { Space } from 'antd';
import { ReactElement, useMemo } from 'react';
import { Redirect, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { CaseTemplatesList } from './CaseTemplate/List';
import { PageTemplateList } from './Pages/List';
import { ReportTemplatesDetails } from './ReportTemplates/Details';
import { ReportTemplatesList } from './ReportTemplates/List';

import './Templates.scss';

const templateSectionValues = ['case', 'page', 'report'] as const;
type TemplateSectionType = (typeof templateSectionValues)[number];

export const Templates = () => {
    const i = useLocalMessage();
    const history = useHistory();
    const location = useLocation();
    const { orgId } = useParams<{ orgId: string }>();

    const actualSection = useMemo(
        () =>
            templateSectionValues.reduce((acc, section) => {
                const regexp = new RegExp(`${section}`);

                const match = regexp.exec(location.pathname);

                if (match) {
                    const parsedSection = match.find(Boolean);

                    if (!typeGuard(parsedSection, templateSectionValues)) {
                        return;
                    }

                    return parsedSection;
                }

                return acc;
            }, '' as TemplateSectionType | undefined),
        [location],
    );

    const tabLabels: Record<TemplateSectionType, ReactElement> = useMemo(
        () => ({
            case: <Space>{i('templates.tabs.cases')}</Space>,
            report: <Space>{i('templates.tabs.reports')}</Space>,
            page: <Space>{i('templates.tabs.pages')}</Space>,
        }),
        [i],
    );

    const tabs = useMemo(
        () =>
            templateSectionValues.map((section) => (
                <THTabs.TabPane tabKey={section} key={section} tab={tabLabels[section]} />
            )),
        [tabLabels],
    );

    return (
        <THLayout>
            <THLayout.Content className="templates__layout-content">
                <THTabs
                    type="card"
                    size="small"
                    activeKey={actualSection}
                    onTabClick={(key) => {
                        if (!typeGuard(key, templateSectionValues)) {
                            return;
                        }

                        let newPath = '';

                        if (key === 'case') {
                            newPath = RouteBuilder.compute('organisation.templates-case', {
                                orgId,
                            });
                        }

                        if (key === 'report') {
                            newPath = RouteBuilder.compute('organisation.templates-report', {
                                orgId,
                            });
                        }

                        if (key === 'page') {
                            newPath = RouteBuilder.compute('organisation.templates-page', {
                                orgId,
                            });
                        }

                        history.push(newPath);
                    }}
                >
                    {tabs}
                </THTabs>
                <Switch>
                    <PrivateRoute path={RouteBuilder.compute('organisation.templates-case')} space={['user']} exact>
                        <CaseTemplatesList />
                    </PrivateRoute>

                    <PrivateRoute path={RouteBuilder.compute('organisation.templates-report')} space={['user']} exact>
                        <ReportTemplatesList />
                    </PrivateRoute>

                    <PrivateRoute path={RouteBuilder.compute('organisation.templates-page')} space={['user']} exact>
                        <PageTemplateList />
                    </PrivateRoute>

                    <PrivateRoute
                        path={RouteBuilder.compute('organisation.templates-report-details')}
                        space={['user']}
                        exact
                    >
                        <ReportTemplatesDetails />
                    </PrivateRoute>
                    <Redirect to={RouteBuilder.compute('organisation.templates-case')} />
                </Switch>
            </THLayout.Content>
        </THLayout>
    );
};

export const Route: RouteType = {
    content: <Templates />,
    path: RouteBuilder.compute('organisation.templates'),
    label: 'Templates',
    space: ['user'],
    menu: {
        label: 'organisation.layout.nav-bar.templates',
        icon: <ICONS.CaseTemplateIcon />,
    },
    breadcrumb: { label: 'breadcrumb.organisation.templates' },
};
