import { openMessage, useLocalMessage } from '@Helpers/';

import { useHistory } from 'react-router-dom';
import { CaseOutput } from 'thehive-sdk';

import client from '@Client';

import { computeListSelector } from '@Organisms/Case/selector';
import RouteBuilder from '@Services/RouteBuilder';
import { NumericInput } from '@Atoms/NumericInput/NumericInput';

export const CaseSearch = () => {
    const i = useLocalMessage();
    const history = useHistory();

    const handleSearch = async (value: number) => {
        try {
            const data = await client.CaseController.one<CaseOutput>({
                selector: computeListSelector(),
                filters: [
                    {
                        field: 'number',
                        type: 'number',
                        values: [value],
                    },
                ],
            });
            history.push(RouteBuilder.compute('cases.details', { caseId: data._id }));
        } catch (error) {
            openMessage('error', i('case.search.caseId.notFound'));
        }
    };

    return <NumericInput onFinish={handleSearch} placeholder={i('case.search.caseId.placeholder')} />;
};
