import { ICONS } from '@Constants/iconsApp';
import { useLocalMessage } from '@Helpers/locale';
import { THTooltip } from '@Molecules/THTooltip/THTooltip';
import { classList } from '@Helpers/className';

import './MandatoryAsterisk.scss';

interface MandatoryAsteriskProps {
    className?: string;
}

export const MandatoryAsterisk = ({ className }: MandatoryAsteriskProps) => {
    const i = useLocalMessage();
    return (
        <sup className={classList('mandatory-asterisk-field__asterisk', className)}>
            <THTooltip title={i('common.mandatory')}>
                <ICONS.Asterisk className="mandatory-asterisk-field__asterisk-icon" />
            </THTooltip>
        </sup>
    );
};
