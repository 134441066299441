import {
    ObjectTypeV1,
    ReportTemplateWidgetEnum,
    alertDefaultFields,
    observableDefaultFields,
    taskDefaultFields,
    ttpDefaultFields,
} from 'thehive-sdk';
export interface BaseWidgetFormProps<T> {
    reportTemplateId: string;
    onFinish: (values: T) => void;
    onCancel: () => void;
    defaultValues?: T;
}

export type TableEntityType =
    | Extract<
          ReportTemplateWidgetEnum,
          | ReportTemplateWidgetEnum.ObservableTable
          | ReportTemplateWidgetEnum.TaskTable
          | ReportTemplateWidgetEnum.TTPTable
          | ReportTemplateWidgetEnum.AlertTable
          | ReportTemplateWidgetEnum.CustomFields
          | ReportTemplateWidgetEnum.TaskList
          | ReportTemplateWidgetEnum.ObservableList
          | ReportTemplateWidgetEnum.TTPList
          | ReportTemplateWidgetEnum.AlertList
      >
    | undefined;

export const AVAILABLE_TABLE_ENTITIES = new Map([
    [
        ReportTemplateWidgetEnum.ObservableTable,
        { label: ObjectTypeV1.OBSERVABLE, defaultColumns: observableDefaultFields },
    ],
    [ReportTemplateWidgetEnum.TaskTable, { label: ObjectTypeV1.TASK, defaultColumns: taskDefaultFields }],
    [ReportTemplateWidgetEnum.TTPTable, { label: ObjectTypeV1.PROCEDURE, defaultColumns: ttpDefaultFields }],
    [ReportTemplateWidgetEnum.AlertTable, { label: ObjectTypeV1.ALERT, defaultColumns: alertDefaultFields }],
    [ReportTemplateWidgetEnum.CustomFields, { label: ObjectTypeV1.CUSTOM_FIELD, defaultColumns: [] }],
]);

export const AVAILABLE_LIST_ENTITIES = new Map([
    [
        ReportTemplateWidgetEnum.ObservableList,
        { label: ObjectTypeV1.OBSERVABLE, defaultColumns: observableDefaultFields },
    ],
    [ReportTemplateWidgetEnum.TaskList, { label: ObjectTypeV1.TASK, defaultColumns: taskDefaultFields }],
    [ReportTemplateWidgetEnum.TTPList, { label: ObjectTypeV1.PROCEDURE, defaultColumns: ttpDefaultFields }],
    [ReportTemplateWidgetEnum.AlertList, { label: ObjectTypeV1.ALERT, defaultColumns: alertDefaultFields }],
]);
