import { memo } from 'react';
import { classList } from '@Helpers/className';
import { THTooltip } from '@Molecules/THTooltip/THTooltip';

import './SeverityTag.scss';

type SeverityDetailsType = {
    label: string;
    name: string;
    tooltip: string;
};

interface SeverityTagProps {
    severity: number;
    onClick?: () => void;
    hideTooltip?: boolean;
}

const SeverityTag = ({ severity, onClick, hideTooltip = false }: SeverityTagProps): JSX.Element => {
    const severityDetails: Record<string, SeverityDetailsType> = {
        '1': {
            label: 'L',
            name: 'low',
            tooltip: 'Low',
        },
        '2': {
            label: 'M',
            name: 'medium',
            tooltip: 'Medium',
        },
        '3': {
            label: 'H',
            name: 'high',
            tooltip: 'High',
        },
        '4': {
            label: 'C',
            name: 'critical',
            tooltip: 'Critical',
        },
    };

    const { label, name, tooltip }: SeverityDetailsType = severityDetails[severity?.toString() || '1'];

    const content = (
        <span
            className={classList('SeverityTag', `SeverityTag--${name}`, { 'SeverityTag--clickable': onClick })}
            onClick={onClick}
        >
            {label}
        </span>
    );

    return !hideTooltip ? (
        <THTooltip title={tooltip} placement="bottom">
            {content}
        </THTooltip>
    ) : (
        content
    );
};

export default memo(SeverityTag);
