import { handleNotificationError } from '@Helpers/notification';
import useDebounce from '@Hooks/useDebounce';
import { useListAllFields } from '@Queries/describeQueries';
import { FormInstance } from 'antd';
import { useState } from 'react';
import { CaseOutput, FilterInput } from 'thehive-sdk';
import { CaseMergeFormValues } from './CaseMerge';

type useCaseMergeInput = {
    form: FormInstance;
    listCasesForMerge: (filter: FilterInput) => Promise<CaseOutput[]>;
    onFinish: (values: CaseMergeFormValues) => Promise<void>;
    onChange: () => void;
    disallowMerge?: boolean;
};

export const useCaseMerge = ({ form, listCasesForMerge, onFinish, onChange, disallowMerge }: useCaseMergeInput) => {
    const { data: describeData } = useListAllFields();
    const { debounce } = useDebounce();

    const [caseList, setCaseList] = useState<CaseOutput[]>([]);
    const [stateLoading, setStateLoading] = useState<boolean>(false);

    const fetchCases = async (value: string): Promise<void> => {
        const number = parseInt(value);

        const searchType = form.getFieldValue('searchType');

        if (value.trim() === '') {
            return;
        }

        try {
            let query;

            if (searchType === 'number' && !isNaN(number)) {
                query = {
                    field: 'number',
                    type: 'number',
                    operator: 'eq',
                    values: `${number}`,
                };
            }
            if (searchType === 'title') {
                query = {
                    field: 'title',
                    type: 'string',
                    operator: 'like',
                    values: value,
                };
            }

            if (query) {
                const describeAttribute = describeData?.case.attributes.find(({ name }: any) => name === searchType);
                const caseList = await listCasesForMerge({ ...query, describeAttribute });
                setCaseList(caseList.filter(({ stage }) => !(disallowMerge && stage === 'Closed')));
            }
        } catch (error) {
            handleNotificationError(error);
        }
    };

    const checkSearchField = (searchType: 'title' | 'number', searchValue: any) => {
        const isNotValid =
            searchType === 'number' &&
            searchValue.length > 0 &&
            !form.getFieldValue('caseData') &&
            (isNaN(searchValue) || isNaN(parseFloat(searchValue)));

        return Promise.resolve(isNotValid ? 'case.merge.form.error.notNumber' : null);
    };

    const handleChange = async (changedValues: any) => {
        // First take account of onChange
        onChange();

        // Second we process in function of changed field
        if ('searchField' in changedValues) {
            const searchField = changedValues['searchField'];
            const checkResult = await checkSearchField(form.getFieldValue('searchType'), searchField);

            if (!searchField || checkResult !== null) {
                setCaseList([]);
                return;
            }

            debounce({
                onClear: () => setCaseList([]),
                onTimeout: () => fetchCases(searchField),
                timeoutDelay: 750,
            });
        }

        if ('searchType' in changedValues) {
            setCaseList([]);
            form.setFieldsValue({ searchField: '' });
        }
    };

    const handleSubmit = async (values: CaseMergeFormValues) => {
        setStateLoading(true);
        try {
            await onFinish(values);
        } finally {
            setStateLoading(false);
        }
    };

    return {
        caseList,
        stateLoading,
        handleChange,
        handleSubmit,
        checkSearchField,
    };
};
