import { TitleSection } from '@Atoms/TitleSection/TitleSection';
import { useLocalMessage } from '@Helpers/locale';
import { THSwitch } from '@Molecules/Inputs/THSwitch/THSwitch';
import { THForm } from '@Molecules/THForm/THForm';
import { CertificateList } from '@Organisms/Certificate/CertificateList/CertificateList';
import Form from 'antd/lib/form';
import { FormInstance } from 'antd/lib/form';

interface SSLConfigFormProps {
    name: string[];
    form: FormInstance;
}

const SSLConfigForm = ({ name, form }: SSLConfigFormProps) => {
    const i = useLocalMessage();

    const nameKey = {
        checkCertificateAuthority: [...name, 'loose', 'checkCertificateAuthority'],
        disableHostnameVerification: [...name, 'loose', 'disableHostnameVerification'],
        stores: [...name, 'trustManager', 'stores'],
    };

    const checkCertificateAuthority: boolean = Form.useWatch(nameKey.checkCertificateAuthority, form);

    return (
        <section className="ssl-config-form">
            <TitleSection className="ssl-config-form__title">{i('configPlateform.section.ssl')}</TitleSection>
            <div className="ssl-config-form__fields">
                <THForm.Item
                    label={i('sslConfig.field.acceptAnyCertificate')}
                    name={nameKey.checkCertificateAuthority}
                    help={i('sslConfig.message.recommended')}
                    valuePropName="checked"
                    className="ssl-config-form__item"
                >
                    <THSwitch className="ssl-config-form__switch" />
                </THForm.Item>

                <CertificateList form={form} name={nameKey.stores} hidden={!checkCertificateAuthority} />

                <THForm.Item
                    label={i('sslConfig.field.disableHostnameVerification')}
                    name={nameKey.disableHostnameVerification}
                    valuePropName="checked"
                    className="ssl-config-form__item"
                >
                    <THSwitch className="ssl-config-form__switch" />
                </THForm.Item>
            </div>
        </section>
    );
};

export default SSLConfigForm;
