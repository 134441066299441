import { ICONS } from '@Constants/iconsApp';
import { THButton } from '@Molecules/Inputs/THButton/THButton';
import { THDropdown } from '@Molecules/Inputs/THDropdown/THDropdown';
import { THMenu } from '@Molecules/THMenu/THMenu';
import { Space } from 'antd';
import { useMemo } from 'react';
import { TableEntityType } from '../FormInterface';
import { sortColumns } from '../../utils';
import { ReportTemplateWidgetEnum, alertFields, observableFields, taskFields, ttpFields } from 'thehive-sdk';
import { useLocalMessage } from '@Helpers/locale';

const columnFields = {
    [ReportTemplateWidgetEnum.AlertTable]: alertFields,
    [ReportTemplateWidgetEnum.ObservableTable]: observableFields,
    [ReportTemplateWidgetEnum.TTPTable]: ttpFields,
    [ReportTemplateWidgetEnum.TaskTable]: taskFields,
    [ReportTemplateWidgetEnum.CustomFields]: [],

    [ReportTemplateWidgetEnum.AlertList]: alertFields,
    [ReportTemplateWidgetEnum.ObservableList]: observableFields,
    [ReportTemplateWidgetEnum.TTPList]: ttpFields,
    [ReportTemplateWidgetEnum.TaskList]: taskFields,
};

interface ColumnFieldSelectorProps {
    actionTitle: string;
    entityType: TableEntityType;
    selectedColumns: string[];
    onClick: (value: string) => void;
    hidePlusIcon?: boolean;
    classes?: {
        button?: string;
        menu?: string;
        item?: string;
    };
}

export const ColumnFieldSelector = ({
    actionTitle,
    entityType,
    selectedColumns,
    onClick,
    hidePlusIcon = false,
    classes,
}: ColumnFieldSelectorProps) => {
    const i = useLocalMessage();

    const fieldsAvailable = useMemo(
        () => (entityType ? ([...columnFields[entityType]].sort(sortColumns) as string[]) : []),
        [entityType],
    );

    const fieldsToSelect = useMemo(
        () =>
            fieldsAvailable.reduce((acc: string[], value: string) => {
                return selectedColumns?.includes(value) ? acc : [...acc, value];
            }, []),
        [fieldsAvailable, selectedColumns],
    );

    return (
        <THDropdown
            triggerElem={() =>
                fieldsToSelect.length > 0 ? (
                    <THButton className={classes?.button} type="link">
                        <Space>
                            {!hidePlusIcon && <ICONS.PlusIcon />}
                            {actionTitle}
                        </Space>
                    </THButton>
                ) : (
                    <></>
                )
            }
            trigger={['click']}
            menuClassName={classes?.menu}
            quitOnMenuItemClick={false}
            align={{
                offset: [0, -5],
            }}
        >
            {fieldsToSelect.map((value) => (
                <THMenu.Item key={value} className={classes?.menu} onClick={() => onClick(value)}>
                    {i({ defaultMessage: value, id: `column.${value}` })}
                </THMenu.Item>
            ))}
        </THDropdown>
    );
};
