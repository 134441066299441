import { FormSubmitButton } from '@Atoms/Buttons/FormSubmitButton/FormSubmitButton';
import { DisplayEmptyData } from '@Atoms/Display/DisplayEmptyData/DisplayEmptyData';
import { HTMLColors } from '@Constants/colors';
import { ICONS } from '@Constants/iconsApp';
import { useLocalMessage } from '@Helpers/locale';
import { THForm } from '@Molecules/THForm/THForm';
import THSelector from '@Molecules/THSelector/THSelector';
import { FormInstance } from 'antd';
import { ReactElement, useState } from 'react';
import { PageTemplateInput, PageTemplateOutput } from 'thehive-sdk';
import { PageTemplateFormValues } from '../PageTemplateForm/PageTemplateForm';

export interface PageTemplateFormated extends PageTemplateOutput {
    _id: string;
    isCategory?: boolean;
    title: string;
    category: string;
    content: string;
}

interface PageTemplateExistingProps {
    form: FormInstance<PageTemplateFormValues>;
    onFinish: (values: PageTemplateFormValues) => void;
    onValuesChange: (e?: any) => void;
    onCancel?: () => void;
    defaultValues?: PageTemplateInput;
    disabled: boolean;
    pageTemplateList: PageTemplateFormated[];
    noFooter?: boolean;
    noForm?: boolean;
    enableSelectAll?: boolean;
    required?: boolean;
}

export const PageTemplateExisting = ({
    form,
    onFinish,
    defaultValues,
    onValuesChange,
    onCancel,
    disabled,
    pageTemplateList,
    noFooter,
    noForm = false,
    enableSelectAll,
    required = true,
}: PageTemplateExistingProps) => {
    const i = useLocalMessage();

    const [templatesSelected, setTemplatesSelected] = useState<PageTemplateFormated[]>([]);

    const sortTemplatesArray = (templates: PageTemplateFormated[]) => {
        templates.sort(function (a, b) {
            // Compare categories
            const categorieComparison = a.category.localeCompare(b.category);
            if (categorieComparison !== 0) {
                return categorieComparison;
            }
            // If the categories are identical, compare the titles
            return a.title.localeCompare(b.title);
        });
        return templates;
    };

    const onExistingPageTemplateSelect = (pageTemplates: PageTemplateFormated) => {
        const lastElement = { ...pageTemplates };
        let _templatesSelected: PageTemplateOutput[] = [...templatesSelected];
        if ((lastElement as PageTemplateFormated)?.isCategory) {
            // select/unselect all elements in category
            const hasAllTemplateFromCategory =
                templatesSelected.filter((element: PageTemplateFormated) => element.category === lastElement.category)
                    .length === pageTemplateList.filter((element) => element.category === lastElement.category).length;
            if (hasAllTemplateFromCategory) {
                _templatesSelected = [
                    ...templatesSelected.filter((element) => element.category !== lastElement.category),
                ];
            } else {
                // get all templates from category
                let allTemplateFromCategory = pageTemplateList.filter(
                    (element) => element.category === lastElement.category && !element.isCategory,
                );

                // avoid duplicates elements
                allTemplateFromCategory = allTemplateFromCategory.reduce((acc, current) => {
                    if (!_templatesSelected.find((elt) => current._id === elt._id)) {
                        return [...acc, current];
                    } else {
                        return acc;
                    }
                }, [] as PageTemplateOutput[]);

                // add them to _templatesSelected
                _templatesSelected = [...templatesSelected, ...allTemplateFromCategory, lastElement];
            }
        } else {
            const action =
                templatesSelected?.filter((element) => element._id === lastElement._id).length > 0 ? 'remove' : 'add';
            if (action === 'remove') {
                // check if all category's templates are present, if yes: remove category from templatesSelected then remove the template
                const hasAllTemplateFromCategory =
                    pageTemplateList.filter(
                        (element: PageTemplateFormated) => element.category === lastElement.category,
                    ).length ===
                    templatesSelected.filter((element) => element.category === lastElement.category).length;
                if (hasAllTemplateFromCategory) {
                    // remove category from array
                    _templatesSelected = templatesSelected.filter((elt) => elt.title !== lastElement.category);
                }
                // remove selected item
                _templatesSelected = _templatesSelected?.filter((element) => element._id !== lastElement?._id);
            } else {
                _templatesSelected = [...templatesSelected, lastElement] as PageTemplateOutput[];
                // if has all templates from category, add it to select the icon
                const hasAllTemplateFromCategory =
                    pageTemplateList.filter(
                        (element: PageTemplateFormated) =>
                            element.category === lastElement.category && !element.isCategory,
                    ).length ===
                    _templatesSelected.filter((element) => element.category === lastElement.category).length;
                if (hasAllTemplateFromCategory) {
                    // get category element and add it if all category's templates are present
                    const category = pageTemplateList.find(
                        (element) => element.title === lastElement.category && element.isCategory,
                    );
                    _templatesSelected = [..._templatesSelected, category as PageTemplateOutput];
                }
            }
        }
        // remove duplicate elements
        const templates = Array.from(new Set(_templatesSelected)).flat();
        setTemplatesSelected(templates);
        form.setFieldsValue({ pageTemplates: templates });
        onValuesChange();
    };

    const FormWrapper = ({ children }: { children: ReactElement }) =>
        noForm ? (
            <>{children}</>
        ) : (
            <THForm<PageTemplateFormValues>
                className="page-template-manage__form"
                form={form}
                onFinish={() => {
                    onFinish({
                        pageTemplates: sortTemplatesArray(templatesSelected.filter((template) => !template.isCategory)),
                    } as PageTemplateFormValues);
                }}
                initialValues={defaultValues}
            >
                {children}
            </THForm>
        );

    return (
        <FormWrapper>
            <THForm.Layout
                className="page-template-manage__form"
                footer={
                    !noFooter && (
                        <FormSubmitButton
                            disabled={disabled}
                            onCancel={onCancel}
                            onSubmit={() => {
                                onFinish({
                                    pageTemplates: sortTemplatesArray(
                                        templatesSelected.filter((template) => !template.isCategory),
                                    ),
                                } as PageTemplateFormValues);
                            }}
                        />
                    )
                }
            >
                <THForm.Item
                    name="pageTemplates"
                    key={'pageTemplates'}
                    label={i('page-template.import.label')}
                    rules={[{ required: required, message: i('page-template.import.error') }]}
                    extraAction={
                        enableSelectAll && (
                            <>
                                <span
                                    className="case-bulk-caseTemplate__field-extra-action"
                                    onClick={() => {
                                        setTemplatesSelected(pageTemplateList || []);
                                        form.setFieldsValue({ pageTemplates: pageTemplateList });
                                        onValuesChange();
                                    }}
                                >
                                    {i('common.select-all')}
                                </span>
                                <span
                                    className="case-bulk-caseTemplate__field-extra-action"
                                    onClick={() => {
                                        setTemplatesSelected([]);
                                        form.setFieldsValue({ pageTemplates: [] });
                                        onValuesChange();
                                    }}
                                >
                                    {i('common.deselect-all')}
                                </span>
                            </>
                        )
                    }
                >
                    <THSelector<PageTemplateFormated>
                        value={templatesSelected}
                        dataSource={Array.from(new Set(pageTemplateList))}
                        filtering={{
                            search: ({ title, category }): string => `${title}` || `${category}`,
                            placeholder: i('page-template.import.search'),
                        }}
                        displayEmpty={<DisplayEmptyData label={i('page-template.none')} />}
                        customSelect={(item) => {
                            return templatesSelected.filter((element) => element._id === item._id).length > 0;
                        }}
                        className="page-template-manage__selector__unstyled-list"
                    >
                        {(item, selected) => {
                            if (item.isCategory) {
                                return (
                                    <div
                                        className="page-template-manage__selector__category"
                                        onClick={() => onExistingPageTemplateSelect(item)}
                                    >
                                        {selected ? <ICONS.Folder color={HTMLColors.BLUE} /> : <ICONS.Folder />}
                                        {item.title}
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className="page-template-manage__selector__item"
                                    onClick={() => onExistingPageTemplateSelect(item)}
                                >
                                    {selected ? <ICONS.FileOutlined color={HTMLColors.BLUE} /> : <ICONS.FileOutlined />}
                                    {item.title}
                                </div>
                            );
                        }}
                    </THSelector>
                </THForm.Item>
            </THForm.Layout>
        </FormWrapper>
    );
};
