import client from '@Client';
import { useLocalMessage } from '@Helpers/locale';
import useListQuery from '@Hooks/useListQuery';
import useValidateForm from '@Hooks/useValidateForm';
import { THRadio } from '@Molecules/Inputs/THRadio/THRadio';
import THDrawer from '@Molecules/THDrawer/THDrawer';
import { ReactElement, useMemo, useState } from 'react';
import { PageOutput, PageTemplateInput, PageTemplateOutput } from 'thehive-sdk';
import { PageTemplateExisting, PageTemplateFormated } from '../PageTemplateExisting/PageTemplateExisting';
import { PageTemplateForm, PageTemplateFormValues } from '../PageTemplateForm/PageTemplateForm';
import { DrawerProps, Tooltip } from 'antd';

import './PageTemplateManage.scss';

const pageTemplateManageMode = ['create', 'add'] as const;
type PageTemplateManageMode = (typeof pageTemplateManageMode)[number];

interface PageTemplateManageProps {
    triggerElem?: ReactElement;
    defaultValues?: PageTemplateInput;
    isOnlyCreate?: boolean;
    drawerTitle?: string;
    pageTemplatesList?: PageOutput[];
    ctaMode?: string[];
    width?: DrawerProps['width'];
    noContent?: boolean;
    visible: boolean;
    defaulPageTemplateCategories?: string[];

    onCreate: (pageTemplate: PageOutput, pageTemplateId?: string) => void;
    onFinish?: (pageTemplate: PageTemplateInput) => void;
    onOpen?: () => void;
    setVisible: (visible: boolean) => void;
}

export const PageTemplateManage = ({
    onCreate,
    onFinish,
    onOpen,
    triggerElem,
    defaultValues,
    isOnlyCreate = false,
    drawerTitle,
    pageTemplatesList,
    ctaMode,
    width = '45vw',
    noContent,
    visible,
    setVisible,
    defaulPageTemplateCategories = [],
}: PageTemplateManageProps) => {
    const i = useLocalMessage();

    const [mode, setMode] = useState<PageTemplateManageMode>();

    const { form, isValid, onFormValuesChange, fieldsTouched, setFieldsTouched } =
        useValidateForm<PageTemplateFormValues>();

    const { data: pageTemplateList = [] } = useListQuery<PageTemplateOutput[]>({
        controller: client.PageTemplateController,
        queryParams: {
            selector: { head: [{ name: 'listPageTemplate' }], name: 'get-all-page-templates' },
        },
        fetchOnStart: !pageTemplatesList ? true : false,
    });

    const allCategoriesFromTemplatesAndCase = useMemo(
        () =>
            pageTemplateList.reduce((acc, { category }) => (acc.includes(category) ? acc : [...acc, category]), [
                ...defaulPageTemplateCategories,
            ] as string[]),
        [pageTemplateList, defaulPageTemplateCategories],
    );

    const allCategoriesFromTemplates = useMemo(
        () =>
            pageTemplateList.reduce(
                (acc, { category }) => (acc.includes(category) ? acc : [...acc, category]),
                [] as string[],
            ),
        [pageTemplateList],
    );

    const sortTemplatesArray = (templates: PageTemplateFormated[]) => {
        templates.sort(function (a, b) {
            // Compare categories
            const categorieComparison = a.category.localeCompare(b.category);
            if (categorieComparison !== 0) {
                return categorieComparison;
            }
            // If the categories are identical, compare the titles
            return a.title.localeCompare(b.title);
        });
        return templates;
    };

    const pageTemplateListFormated = useMemo(() => {
        const sortedPageTemplateList = sortTemplatesArray(pageTemplateList);

        allCategoriesFromTemplates.forEach((category: string) => {
            const firstOfCategory = sortedPageTemplateList.findIndex((elt) => elt.category === category);
            sortedPageTemplateList.splice(firstOfCategory, 0, {
                title: category,
                category,
                isCategory: true,
                _id: category,
                id: '',
                createdBy: '',
                createdAt: 0,
                content: '',
                _type: '',
                slug: '',
                order: 0,
                updatedAt: 0,
            });
        });
        return Array.from(new Set(sortedPageTemplateList));
    }, [pageTemplateList, allCategoriesFromTemplates]);

    const onFinishHandler = (values: PageTemplateFormValues) => {
        const { title = '', category = '', content = '' } = values || {};
        defaultValues
            ? onCreate({ title, category, content } as PageOutput, defaultValues._id as string)
            : onCreate({ title, category, content } as PageOutput);
        if (!noContent && onFinish) {
            onFinish({ title, content, category });
        }
        setFieldsTouched(false);
    };

    const onFinishExistingSelect = ({ pageTemplates }: PageTemplateFormValues): void => {
        if (onFinish) {
            pageTemplates?.forEach((template) => {
                // get rid of categories item
                if (template._id !== '') onFinish(template);
            });
            setFieldsTouched(false);
        }
        setVisible(false);
    };

    return (
        <THDrawer
            visible={visible}
            title={drawerTitle || i('page-template.create.drawer.label')}
            triggerElem={triggerElem}
            destroyOnClose
            afterVisibleChange={(visible) => {
                if (visible) {
                    onOpen?.();
                }
                setMode(undefined);
                form.resetFields();
            }}
            disableClose={fieldsTouched}
            onClose={() => setVisible(false)}
            className="drawer-form"
            width={width}
        >
            {(toggleDrawer) => (
                <div className="page-template-manage__body">
                    {!isOnlyCreate && (
                        <div className="page-template-manage__switch">
                            <span className="page-template-manage__choose">
                                {i('page-template.create.form.mode.choose')}
                            </span>
                            <THRadio.Group value={mode} onChange={(e) => setMode(e.target.value)} buttonStyle="solid">
                                {(ctaMode || pageTemplateManageMode).map((mode) => {
                                    if (mode === 'create' || mode === 'create-template') {
                                        return (
                                            <THRadio.Button key={mode} value={mode}>
                                                {i(`page-template.create.form.mode.${mode}`)}
                                            </THRadio.Button>
                                        );
                                    } else {
                                        if (pageTemplateList.length === 0) {
                                            return (
                                                <Tooltip key={mode} title="No page template created">
                                                    <THRadio.Button value={mode} disabled={true}>
                                                        {i(`page-template.create.form.mode.${mode}`)}
                                                    </THRadio.Button>
                                                </Tooltip>
                                            );
                                        } else {
                                            return (
                                                <THRadio.Button key={mode} value={mode}>
                                                    {i(`page-template.create.form.mode.${mode}`)}
                                                </THRadio.Button>
                                            );
                                        }
                                    }
                                })}
                            </THRadio.Group>
                        </div>
                    )}

                    {(isOnlyCreate || mode === (ctaMode || pageTemplateManageMode)[0]) && (
                        <PageTemplateForm
                            onFinish={({ another, ...values }) => {
                                form.resetFields();
                                setFieldsTouched(false);
                                setVisible(another ? true : false);
                                onFinishHandler(values);
                            }}
                            allCategories={allCategoriesFromTemplatesAndCase}
                            form={form}
                            onValuesChange={() => {
                                setFieldsTouched(true);
                                onFormValuesChange();
                            }}
                            defaultValues={defaultValues}
                            onCancel={() => {
                                form.resetFields();
                                toggleDrawer();
                                setVisible(false);
                                setFieldsTouched(false);
                            }}
                            disabled={!(isValid && fieldsTouched)}
                            noContent={noContent}
                        />
                    )}
                    {mode === (ctaMode || pageTemplateManageMode)[1] && (
                        <PageTemplateExisting
                            pageTemplateList={pageTemplateListFormated}
                            onFinish={(values: PageTemplateFormValues) => {
                                onFinishExistingSelect(values);
                                toggleDrawer();
                            }}
                            form={form}
                            onValuesChange={() => {
                                setFieldsTouched(true);
                                onFormValuesChange();
                            }}
                            defaultValues={defaultValues}
                            onCancel={() => {
                                form.resetFields();
                                toggleDrawer();
                                setVisible(false);
                                setFieldsTouched(false);
                            }}
                            disabled={!(isValid && fieldsTouched)}
                        />
                    )}
                </div>
            )}
        </THDrawer>
    );
};
