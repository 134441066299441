import { TitleSection } from '@Atoms/TitleSection/TitleSection';
import { useLocalMessage } from '@Helpers/locale';
import { THInput } from '@Molecules/Inputs/THInput/THInput';
import { THInputNumber } from '@Molecules/Inputs/THInputNumber/THInputNumber';
import { THPassword } from '@Molecules/Inputs/THPassword/THPassword';
import { THRadio } from '@Molecules/Inputs/THRadio/THRadio';
import { THSelect } from '@Molecules/Inputs/THSelect/THSelect';
import { THSwitch } from '@Molecules/Inputs/THSwitch/THSwitch';
import { THForm } from '@Molecules/THForm/THForm';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import _ from 'lodash';
import { useEffect } from 'react';

const typeValueList = [
    { label: 'http', value: 'http' },
    { label: 'https', value: 'https' },
];

interface ProxyConfigFormProps {
    name: string[];
    form: FormInstance;
}

const ProxyConfigForm = ({ name, form }: ProxyConfigFormProps) => {
    const i = useLocalMessage();

    const nameKey = {
        proxy: {
            state: [...name, 'state'],
            protocol: [...name, 'protocol'],
            host: [...name, 'host'],
            port: [...name, 'port'],
            authEnabled: [...name, 'auth', 'enabled'],
            username: [...name, 'principal'],
            password: [...name, 'password'],
        },
    };

    const proxyState = Form.useWatch(nameKey.proxy.state, form);
    const overrideGlobal = proxyState !== 'global';
    const proxyEnabled = overrideGlobal && proxyState === 'enabled';
    const authEnabled = Form.useWatch(nameKey.proxy.authEnabled, form);

    useEffect(() => {
        if (!form.getFieldValue(nameKey.proxy.state)) {
            const state = form.getFieldValue(nameKey.proxy.host) ? 'enabled' : 'disabled';
            form.setFieldsValue(_.set(form.getFieldsValue(), nameKey.proxy.state.join('.'), state));
        }
        if (form.getFieldValue(nameKey.proxy.username)) {
            form.setFieldsValue(_.set(form.getFieldsValue(), nameKey.proxy.authEnabled.join('.'), true));
        }
    }, [form, name]); // eslint-disable-line

    const handleChange = (checked: boolean) => {
        if (!checked) {
            const before = form.getFieldsValue();
            let after = _.set(before, nameKey.proxy.username.join('.'), null);
            after = _.set(after, nameKey.proxy.password.join('.'), null);
            form.setFieldsValue(after);
        }
    };

    return (
        <section className="proxy-config-form">
            <TitleSection>{i('configPlateform.section.proxySetting')}</TitleSection>

            <div className="proxy-config-form__section">
                <THForm.Item name={nameKey.proxy.state}>
                    <THRadio.Group defaultValue="default" buttonStyle="solid">
                        <THRadio.Button value="default">{i('configProxy.field.defaultConfig')}</THRadio.Button>
                        <THRadio.Button value="enabled">{i('common.enabled')}</THRadio.Button>
                        <THRadio.Button value="disabled">{i('common.disabled')}</THRadio.Button>
                    </THRadio.Group>
                </THForm.Item>

                <THForm.Item
                    name={nameKey.proxy.protocol}
                    label={i('configProxy.field.type')}
                    hidden={!proxyEnabled}
                    rules={[{ required: proxyEnabled }]}
                    className="proxy-config-form__item"
                >
                    <THSelect options={typeValueList} />
                </THForm.Item>

                <THForm.Item
                    label={i('configProxy.field.adress')}
                    name={nameKey.proxy.host}
                    hidden={!proxyEnabled}
                    rules={[{ required: proxyEnabled }]}
                    className="proxy-config-form__item"
                >
                    <THInput />
                </THForm.Item>

                <THForm.Item
                    label={i('configProxy.field.port')}
                    name={nameKey.proxy.port}
                    hidden={!proxyEnabled}
                    className="proxy-config-form__item"
                >
                    <THInputNumber placeholder="3128" />
                </THForm.Item>
            </div>

            <div className="proxy-config-form__section">
                <THForm.Item
                    label={i('configProxy.field.authentication')}
                    name={nameKey.proxy.authEnabled}
                    valuePropName="checked"
                    hidden={!proxyEnabled}
                    className="proxy-config-form__item"
                >
                    <THSwitch onChange={handleChange} />
                </THForm.Item>

                <THForm.Item
                    hidden={!proxyEnabled || !authEnabled}
                    rules={[{ required: proxyEnabled && authEnabled, message: i('configPlateform.required.general') }]}
                    label={i('configProxy.field.username.name')}
                    name={nameKey.proxy.username}
                    className="proxy-config-form__item"
                >
                    <THInput placeholder={i('configProxy.field.username.placeholder')} />
                </THForm.Item>

                <THForm.Item
                    hidden={!proxyEnabled || !authEnabled}
                    rules={[{ required: proxyEnabled && authEnabled, message: i('configPlateform.required.general') }]}
                    className="proxy-config-form__item"
                    label={i('configProxy.field.password.name')}
                    name={nameKey.proxy.password}
                >
                    <THPassword placeholder={i('configProxy.field.password.placeholder')} />
                </THForm.Item>
            </div>
        </section>
    );
};

export default ProxyConfigForm;
