import { ICONS } from '@Constants/iconsApp';
import { useLocalMessage } from '@Helpers/locale';
import { replaceAll } from '@Helpers/string';
import { THButton } from '@Molecules/Inputs/THButton/THButton';
import { THDropdown } from '@Molecules/Inputs/THDropdown/THDropdown';
import { THMenu } from '@Molecules/THMenu/THMenu';
import { useListAllFields } from '@Queries/describeQueries';
import { useCallback, useMemo } from 'react';
import { ObjectTypeV1 as ObjectType } from 'thehive-sdk';

import './VariableSelector.scss';

type CursorPosition = {
    start: number | null;
    end: number | null;
};

interface VariableSelectorProps {
    cursor: CursorPosition;
    parentFieldValue: string;
    onSelect: (parsedParentFieldValue: string) => void;
    objectType?: ObjectType;
    format?: string;
}

export const VariableSelector = ({
    cursor,
    parentFieldValue,
    onSelect,
    objectType = ObjectType.CASE,
    format = '{{%var}}',
}: VariableSelectorProps) => {
    const i = useLocalMessage();
    const { data } = useListAllFields();

    const templateVariables = useMemo(() => {
        const describeField = data?.[objectType];
        return Object.entries(describeField && describeField.attributes ? describeField.attributes : {})
            .reduce(
                (acc: { name: string; help?: any }[], [, value]: any[]) => [
                    ...acc,
                    { ...value, name: `${value.name}` },
                ],
                [],
            )
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [data, objectType]);

    const handleClick = useCallback(
        (name: string) => () => {
            const { start, end } = cursor;
            const nameFormatted = replaceAll(format, '%var', [`${objectType}.${name}`]);
            let valueParsed = '';

            if (start !== null && end !== null) {
                valueParsed =
                    (parentFieldValue ? parentFieldValue.substring(0, start) : '') +
                    nameFormatted +
                    (parentFieldValue ? parentFieldValue.substring(end, parentFieldValue.length) : '');
            } else {
                valueParsed = (parentFieldValue ?? '') + nameFormatted;
            }

            onSelect(valueParsed);
        },
        [cursor, format, objectType, onSelect, parentFieldValue],
    );

    return (
        <THDropdown
            triggerElem={() => (
                <THButton className="report-template-helper__menu-btn" type="link">
                    <div className="report-template-helper__menu-btn-content">
                        <span>{i('notification.notifiers.template.extra-action')}</span>
                        <ICONS.DownOutlined />
                    </div>
                </THButton>
            )}
            trigger={['click']}
            menuClassName="report-template-helper__menu"
            quitOnMenuItemClick={false}
            align={{
                offset: [0, -6],
            }}
        >
            {templateVariables.map(({ name, help }) => (
                <THMenu.Item onClick={handleClick(name)} key={name}>
                    <div className="template-helper__menu-item">
                        <span className="template-helper__name">{name}</span>
                        {help && <span className="template-helper__help">{help}</span>}
                    </div>
                </THMenu.Item>
            ))}
        </THDropdown>
    );
};
