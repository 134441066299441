import { ParamsType } from '@Helpers/locale';
import { Rule } from 'antd/lib/form';
import { MessageDescriptor } from 'react-intl';

const regex = new RegExp(/^[0-9]*$/);

export const MFA_LENGTH = 6;

export const MFA_RULES = (i: (message: string | MessageDescriptor, params?: ParamsType) => string) =>
    [
        {
            validator(_, value) {
                if (value.length === MFA_LENGTH && regex.test(value)) {
                    return Promise.resolve();
                }
                return Promise.reject({ message: i('mfa.error.invalidCode', { codeSize: MFA_LENGTH }) });
            },
        },
    ] as Rule[];
