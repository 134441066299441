import { Tabs, TabsProps } from 'antd';
import './THTabs.scss';

type THTabsProps = TabsProps;

export const THTabs = (props: THTabsProps) => {
    return <Tabs className="th-tabs" {...props} />;
};

THTabs.TabPane = Tabs.TabPane;
