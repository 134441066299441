import ExternalLink from '@Atoms/ExternalLink/ExternalLink';
import { ICONS } from '@Constants/iconsApp';
import { THMarkdown } from '@Molecules/Inputs/THMarkdown/THMarkdown';
import React, { useRef } from 'react';

import './CaseReportPreview.scss';
import { useLocalMessage } from '@Helpers/locale';
import client from '@Client';
import { buildUrlWithQueryParameters } from '@Helpers/string';

interface CaseReportPreviewProps {
    reportRender: string;
    format: string;
    caseId: string;
    reportTemplateId: string;
}

export const CaseReportPreview = ({ reportRender, format, caseId, reportTemplateId }: CaseReportPreviewProps) => {
    const i = useLocalMessage();
    const frameRef = useRef<HTMLIFrameElement>(null);

    const handleLoad = () => {
        const frameContentHeight = frameRef.current?.contentWindow?.document?.body?.scrollHeight;

        if (frameContentHeight) {
            frameRef.current.height = frameContentHeight + 'px';
        }
    };

    return (
        <div className="case-report-preview">
            <div className="case-report-preview__action">
                <ExternalLink
                    url={buildUrlWithQueryParameters(client.apiUrl + '/v1/caseReport/render', {
                        caseId,
                        format,
                        caseReportTemplateId: reportTemplateId,
                    })}
                    className="case-report-preview__link"
                >
                    {i('report.create.form.preview.link')} <ICONS.ExternalLinkIcon />
                </ExternalLink>
            </div>

            {format === 'html' && (
                <iframe
                    className="case-report-preview__frame"
                    srcDoc={reportRender}
                    sandbox="allow-same-origin"
                    ref={frameRef}
                    onLoad={handleLoad}
                    scrolling="no"
                />
            )}

            {format === 'markdown' && <THMarkdown disabled borderless value={reportRender} />}
        </div>
    );
};
