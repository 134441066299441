import { Divider } from 'antd';

import './FieldBlock.scss';

interface FieldBlockProps {
    title: string;
    children: React.ReactNode;
    footerAction?: React.ReactNode;
    className?: string;
}

export const FieldBlock = ({ title, children, footerAction, className }: FieldBlockProps) => {
    return (
        <div className={className}>
            <Divider orientation="left" className="fieldBlock__title">
                {title}
            </Divider>
            <div>{children}</div>
            <div className="fieldBlock__action">{footerAction}</div>
        </div>
    );
};
