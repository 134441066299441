import React, { createContext, ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { queryClient } from '@Client';
import { useLocalMessage, UseLocalMessageType } from '@Helpers/locale';
import { GetFeedType, GlobalStreamSrv, GlobalStreamLongPollingSrv } from '@Services/StreamSrv';
import { Modal } from 'antd';
import { StatusContext } from './StatusProvider';

export const StreamContext = createContext<StreamContextType>({ streamReady: false });

type StreamContextType = {
    streamReady: boolean;
};

export const StreamProvider = ({ children }: { children: ReactElement | undefined }): ReactElement => {
    const i = useLocalMessage();
    const { config, features, version } = useContext(StatusContext);
    const [streamReady, setStreamReady] = useState(false);

    const feedInterceptor = useCallback(
        (result: GetFeedType): void => {
            if (result.status === 220) {
                openModalInfo(i);
            }
        },
        [i],
    );

    useEffect(() => {
        if (version) {
            if (features.has('StreamSSE')) {
                GlobalStreamSrv.start()?.then(() => {
                    setStreamReady(true);
                });
            } else {
                GlobalStreamLongPollingSrv.initialise({
                    pollingDuration: config.pollingDuration,
                    feedInterceptor,
                })?.then(() => {
                    setStreamReady(true);
                });
            }
        }
    }, [feedInterceptor, features, version]); // eslint-disable-line

    return <StreamContext.Provider value={{ streamReady }}>{children}</StreamContext.Provider>;
};

let currentModal: null | any = null;

export function destroyModal() {
    if (currentModal !== null) {
        Modal.destroyAll();
        currentModal = null;
    }
}

function openModalInfo(local: UseLocalMessageType) {
    if (currentModal === null) {
        currentModal = Modal.warning({
            title: local('session-expiration-modal.title'),
            closable: false,
            okText: local('general.ok'),
            content: <p>{local('session-expiration-modal.description')}</p>,
            onOk: () => {
                destroyModal();
                queryClient.refetchQueries(['currentUser']).then(() => {
                    GlobalStreamLongPollingSrv.cancelFeed()
                        .then(() => {
                            GlobalStreamLongPollingSrv.relaunchFeed();
                        })
                        .catch(() => {
                            //empty
                        });
                });
            },
        });
    }
}
