import { useCallback, useEffect, useMemo, useState } from 'react';
import useFilters from '@Hooks/useFilters';
import ProvidedWidgetFormFilter from '@Organisms/Dashboard/WidgetManage/WidgetFormFilter/ProvidedWidgetFormFilter';
import { ColumnField } from '../ColumnField/';
import { SortField } from '../SortField';
import { THSelect } from '@Molecules/Inputs/THSelect/THSelect';
import { convertAPIFilters } from '@Helpers/filters';
import { useListAllFields } from '@Queries/describeQueries';
import { FieldBlock } from '../FieldBlock';
import { FormSubmitButton } from '@Atoms/Buttons/FormSubmitButton/FormSubmitButton';
import { THForm } from '@Molecules/THForm/THForm';
import { AVAILABLE_TABLE_ENTITIES, BaseWidgetFormProps, TableEntityType } from '../FormInterface';
import { ReportTemplateWidgetEnum, SortOptions, convertToSearchQuery } from 'thehive-sdk';
import useValidateForm from '@Hooks/useValidateForm';
import { Form, Space } from 'antd';
import { THInputNumber } from '@Molecules/Inputs/THInputNumber/THInputNumber';
import { THSwitch } from '@Molecules/Inputs/THSwitch/THSwitch';
import { useLocalMessage } from '@Helpers/locale';
import { capitalizeFirstLetter } from '@Helpers/string';

import './TableForm.scss';
import { THInput } from '@Molecules/Inputs/THInput/THInput';

interface TableFormValues {
    columns: string[];
    protectData?: boolean;
    filter?: any;
    entity?: ReportTemplateWidgetEnum;
    _kind?: ReportTemplateWidgetEnum;
    sort?: SortOptions[];
    maxElements?: number;
}

type TableFormProps = BaseWidgetFormProps<TableFormValues>;

export const TableForm = ({ onCancel, onFinish, defaultValues }: TableFormProps) => {
    const i = useLocalMessage();
    const [titleLength, setTitleLength] = useState<number>(0);
    const { form } = useValidateForm();
    const { data: describeOutput } = useListAllFields();

    const filterManager = useFilters();

    useEffect(() => {
        if (defaultValues) {
            const { _kind, filter, sort, ...values } = defaultValues;
            if (_kind) {
                values.entity = _kind;
            }

            const objectType = _kind && AVAILABLE_TABLE_ENTITIES.get(_kind);
            const convertedFilter =
                (!!_kind && objectType && convertAPIFilters(filter, describeOutput?.[objectType.label])) || [];

            const convertedSort = sort?.reduce((accumulator, entry) => {
                if ('field' in entry) {
                    accumulator.push(entry);
                } else {
                    Object.entries(entry).map(([fieldName, direction]) =>
                        accumulator.push({ field: fieldName, direction }),
                    );
                }
                return accumulator;
            }, [] as any[]);

            form.setFieldsValue({ ...values, filter: convertedFilter, sort: convertedSort });
        }
    }, [defaultValues, describeOutput, form]);

    const entity = Form.useWatch<TableEntityType>(['entity'], form);

    const currentTableOption = useMemo(() => (entity ? AVAILABLE_TABLE_ENTITIES.get(entity) : undefined), [entity]);

    const handleResetForm = useCallback(
        (value: any) => {
            if ('entity' in value) {
                const defaultColumns = AVAILABLE_TABLE_ENTITIES.get(value.entity)?.defaultColumns || [];
                form.setFieldsValue({
                    maxElements: undefined,
                    columns: defaultColumns,
                    sort: [],
                    filter: [],
                    protectData: false,
                });
                filterManager.clearFilters();
            }
        },
        [filterManager, form],
    );

    const handleFinish = useCallback(
        ({ filter, sort, ...values }: any) => {
            const returnedData = {
                ...defaultValues,
                ...values,
                _kind: values.entity,
                filter: convertToSearchQuery(filter, true),
                sort: sort ? sort.map(({ field, direction }: any) => ({ field, direction })) : [],
            };
            onFinish(returnedData);
        },
        [defaultValues, onFinish],
    );

    const checkEntity = useCallback(() => {
        return entity !== undefined && entity !== ReportTemplateWidgetEnum.CustomFields;
    }, [entity]);

    return (
        <THForm<TableFormValues>
            form={form}
            name="report-table-form"
            onFinish={handleFinish}
            initialValues={defaultValues}
            onValuesChange={handleResetForm}
        >
            <THForm.Layout footer={<FormSubmitButton disabled={!entity} onCancel={onCancel} />}>
                {/* Choose table type */}
                <THForm.Item
                    name="title"
                    label={i('common.title')}
                    rules={[
                        {
                            max: 512,
                            message: i('report-template.widgets.title.error', {
                                curr: titleLength.toString(),
                            }),
                        },
                    ]}
                >
                    <THInput onChange={(e) => setTitleLength(e.target.value.length)} />
                </THForm.Item>
                <FieldBlock
                    title={i('report-template.table.form.block.parameters')}
                    className="case-template-tableForm-parameters"
                >
                    <Space align="center" direction="horizontal" size="middle" className="tableForm__parameters">
                        <THForm.Item
                            name="entity"
                            label={i('report-template.table.entity.label')}
                            rules={[{ required: true, message: i('report-template.table.form.entity.required') }]}
                            className="tableForm__parameters-entity"
                        >
                            <THSelect
                                options={Array.from(AVAILABLE_TABLE_ENTITIES).map(([value, data]) => ({
                                    value,
                                    label: capitalizeFirstLetter(
                                        i({ defaultMessage: data.label, id: `entities.${data.label}` }),
                                    ),
                                }))}
                            />
                        </THForm.Item>

                        {checkEntity() && (
                            <THForm.Item
                                name="maxElements"
                                label={i('report-template.table.max-elements.label')}
                                className="tableForm__parameters-maxElement"
                            >
                                <THInputNumber placeholder={i('report-template.table.max-elements.placeholder')} />
                            </THForm.Item>
                        )}
                    </Space>

                    <THForm.Item
                        name="protectData"
                        label={i('report-template.table.fang-data.label')}
                        valuePropName="checked"
                        hidden={entity !== ReportTemplateWidgetEnum.ObservableTable}
                    >
                        <THSwitch />
                    </THForm.Item>
                </FieldBlock>

                {checkEntity() && (
                    <>
                        <ColumnField
                            form={form}
                            entityType={entity}
                            name="columns"
                            title={i('report-template.table.form.block.columns')}
                            actionTitle={i('report-template.table.form.addColumn')}
                        />
                        <SortField
                            form={form}
                            entityType={currentTableOption?.label}
                            describeOutput={describeOutput}
                            title={i('report-template.table.form.block.sort')}
                            actionTitle={i('report-template.table.form.addSort')}
                        />

                        <FieldBlock
                            title={i('report-template.table.form.block.filters')}
                            className="case-template-tableForm-filters"
                        >
                            <THForm.Item name="filter" className="form-item no-value">
                                <ProvidedWidgetFormFilter
                                    entity={currentTableOption?.label as string}
                                    externalFilterManager={filterManager}
                                />
                            </THForm.Item>
                        </FieldBlock>
                    </>
                )}
            </THForm.Layout>
        </THForm>
    );
};
