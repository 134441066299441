import { UseLocalMessageType } from '@Helpers/locale';
import { TTPEventList } from './eventList';
import { ActionListRendererParams, actionListRenderer } from '@Helpers/actionListRenderer';
import { ProcedureOutput } from 'thehive-sdk';
import { ICONS } from '@Constants/iconsApp';
import { handleNotificationError } from '@Helpers/notification';

interface TTPActionListProps {
    i: UseLocalMessageType;
    eventList: TTPEventList;
}

export const actionList = ({ i, eventList }: TTPActionListProps): ActionListRendererParams<ProcedureOutput> =>
    actionListRenderer<ProcedureOutput>((selectedItems, onSuccess) => {
        const TTPListIds = selectedItems.map(({ _id }) => _id);

        const isMultiple = selectedItems.length > 1;

        return {
            bulkDelete: {
                label: i('general.delete'),
                icon: <ICONS.DeleteIcon />,
                confirm: true,
                message: i(
                    isMultiple ? 'procedure.list.actions.delete.multiple' : 'procedure.list.actions.delete.single',
                ),
                scope: 'list',
                query: (): void => {
                    eventList
                        .onBulkDelete(TTPListIds)
                        .then(() => onSuccess(true))
                        .catch(handleNotificationError);
                },
            },
        };
    });
