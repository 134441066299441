import { Dispatch, ReactElement, SetStateAction, createContext, useState } from 'react';

type OrgSwitcherOutput = {
    blockOrgSwitch: boolean;
    setBlockOrgSwitch: Dispatch<SetStateAction<boolean>>;
};

export const OrgSwitcherContext = createContext<OrgSwitcherOutput>({
    blockOrgSwitch: false,
    setBlockOrgSwitch: () => {
        // empty
    },
});

interface OrgSwitcherProviderProps {
    children: ReactElement;
}

export const OrgSwitcherProvider = ({ children }: OrgSwitcherProviderProps) => {
    const [blockOrgSwitch, setBlockOrgSwitch] = useState(false);

    return (
        <OrgSwitcherContext.Provider value={{ blockOrgSwitch, setBlockOrgSwitch }}>
            {children}
        </OrgSwitcherContext.Provider>
    );
};
